type MapsResponse = {
  results: []
  status: 'ZERO_RESULTS' | 'OK'
}

export const useGoogleMaps = () => {
  const config = useAppConfig()
  const language = config.currentLanguage
  const { load } = useScriptGoogleMaps({
    apiKey: useRuntimeConfig().public.google,
    //@ts-ignore patched node_modules doens't recognize language
    language: language ?? 'en',
  })

  const isPostalCodeValid = async (
    country: string = '',
    postalCode: string = ''
  ) => {
    if (!postalCode || !country) return false
    const instance = await load()
    const maps = (await instance.maps) as any as typeof google.maps
    const geo = new maps.Geocoder()
    try {
      await geo.geocode({
        componentRestrictions: {
          country: country,
          postalCode: postalCode,
        },
      })
      return true
    } catch (e) {
      return false
    }
  }

  return { isPostalCodeValid }
}
